export default {
  path: '/settings',
  name: 'settings',
  redirect: { name: 'settings.dashboard' },
  component: () =>
    import(/* webpackChunkName: "settings" */ '@/views/Home/Settings'),
  children: [
    {
      path: 'dashboard',
      name: 'settings.dashboard',
      meta: {
        requiresPermissions: ['VIEW_SETTINGS'],
      },
      component: () =>
        import(
          /* webpackChunkName: "settings.dashboard" */ '@/views/Home/Settings/Dashboard'
        ),
    },
    {
      path: 'pages/content',
      name: 'settings.content',
      component: () =>
        import(
          /* webpackChunkName: "settings.content" */ '@/views/Home/Settings/Content/index.vue'
        ),
      props: {
        pageType: 'privacy',
        pageTitle: 'Privacy Policy',
      },
    },
    {
      path: 'pages/privacy',
      name: 'settings.pages.privacy',
      meta: {
        requiresPermissions: ['VIEW_SETTINGS'],
      },
      component: () =>
        import(
          /* webpackChunkName: "settings.pages.privacy" */ '@/views/Home/Settings/PageManager/index.vue'
        ),
      props: {
        pageType: 'privacy',
        pageTitle: 'Privacy Policy',
      },
    },
    {
      path: 'pages/terms-of-service',
      name: 'settings.pages.terms-of-service',
      meta: {
        requiresPermissions: ['VIEW_SETTINGS'],
      },
      component: () =>
        import(
          /* webpackChunkName: "settings.pages.terms" */ '@/views/Home/Settings/PageManager/index.vue'
        ),
      props: {
        pageType: 'terms_of_service',
        pageTitle: 'Terms of Service',
      },
    },
    {
      path: 'version-control',
      name: 'settings.version-control',
      meta: {
        requiresPermissions: ['VIEW_SETTINGS'],
      },
      component: () =>
        import(
          /* webpackChunkName: "settings.version-control" */ '@/views/Home/Settings/VersionControl/index'
        ),
    },
    {
      path: 'organisation/:tab',
      name: 'settings.organisation',
      meta: {
        requiresPermissions: ['VIEW_SETTINGS'],
      },
      component: () =>
        import(
          /* webpackChunkName: "settings.organisation" */ '@/views/Home/Settings/Organisation/index.vue'
        ),
      props: true,
      // Set default route to tab "sso" if no tab is provided
      beforeEnter: (to, from, next) => {
        if (!to.params.tab) {
          next({ name: 'settings.organisation', params: { tab: 'general' } })
        } else {
          next()
        }
      },
    },
    {
      path: 'organisation/templates/:id',
      name: 'settings.organisation.template.details',
      meta: {
        requiresPermissions: ['VIEW_TEMPLATE'],
      },
      component: () =>
        import(
          /* webpackChunkName: "settings.organisation.template.details" */ '@/views/Home/Settings/Organisation/Templates/TemplateDetails.vue'
        ),
    },
  ],
}
