import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Invoice extends Model {
  resource() {
    return 'invoices'
  }

  get buildingName() {
    if (!this.maintenance || !this.maintenance.building) return 'NA'
    return this.maintenance.building.name
  }

  get propertyName() {
    if (
      !this.maintenance ||
      !this.maintenance.user ||
      !this.maintenance.user.property
    )
      return 'NA'
    return this.maintenance.user.property.name
  }

  get supplierName() {
    if (!this.supplier) return 'NA'
    return this.supplier.company_name
  }

  get fileName() {
    if (!this.file) return 'NA'
    return this.file.name
  }

  get statusLabel() {
    return this.deleted_at ? 'Deleted' : this.status
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get updatedDate() {
    return dateFormat(this.updated_at)
  }

  get amountFormat() {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(this.amount)
  }
}
