import Category from '@/models/Category'
import Api from '@/services/api'
import { each, find } from 'lodash'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    categoryDetails: null,
    queryParams: null,
    scrollPosition: 0,
    initList: [],
  },

  mutations: {
    setCategoryList(state, categories) {
      each(categories, (category) => {
        const exist = find(state.list, { id: category.id })
        if (!exist) {
          state.list.push(new Category(category))
        }
      })
    },

    setInitCategories(state, categories) {
      each(categories, (category) => {
        const exist = find(state.initList, { id: category.id })
        if (!exist) {
          state.initList.push(new Category(category))
        }
      })
    },

    clearCategoryList(state) {
      state.list = []
      state.initList = []
    },

    setCategoryListMeta(state, meta) {
      state.listMeta = meta
    },

    setCategoryDetails(state, category) {
      if (category instanceof Category) {
        state.categoryDetails = category
      } else {
        state.categoryDetails = new Category(category)
      }
    },

    clearCategoryDetails(state) {
      state.categoryDetails = null
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getCategories({ commit, rootGetters }, params) {
      let res = null

      if (params.paginate) {
        const query = Category.page(params.page || 1).limit(params.limit || 30)
        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }

        if (params.filter !== undefined) {
          query.where('is_active', params.filter)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        // Add building filter if not nil
        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.where('type', params.type || CATEGORY_TYPE.general)

        query.include('buildings')

        res = await query.get()

        commit('setCategoryList', res.data)
        commit('setInitCategories', res.data)

        commit('setCategoryListMeta', res.meta)
      } else {
        res = await Category.where('type', params.type || CATEGORY_TYPE.general)
          .params({ paginate: false })
          .get()

        return res.data
      }
    },

    async getForSelects({ commit, rootGetters }, params) {
      const query = Category.params({
        paginate: false,
      })

      query.where('type', params.type || CATEGORY_TYPE.general)

      if (params.filter.buildingId) {
        query.where('building_id', params.filter.buildingId)
      }

      const res = await query.get()

      return res.data
    },

    async getCategoryDetails({ commit }, id) {
      const { data } = await Api.get(`categories/${id}/?include=buildings`)
      commit('setCategoryDetails', data.data)
    },

    async createCategory({ commit }, formData) {
      const { data } = await Api.post(`categories`, formData)
      commit('setCategoryDetails', data.data)
    },

    async updateCategory({ commit }, formData) {
      const { data } = await Api.put(
        `categories/${formData.id}/?include=buildings`,
        formData
      )
      commit('setCategoryDetails', data.data)
    },

    async updatePositions({ commit }, formData) {
      const { data } = await Api.post(`categories/updatePositions`, formData)

      return data
    },
  },
}
