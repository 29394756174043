import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class ChartOfAccount extends Model {
  resource() {
    return 'chart_of_accounts'
  }

  get buildingName() {
    if (!this.buildings || this.buildings.length === 0) return 'NA'
    return this.buildings.map((building) => building.name).join(', ')
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get updatedDate() {
    return dateFormat(this.updated_at)
  }
}
