const PERMISSION = {
  USERS_VIEW: 'VIEW_USERS',
  USERS_CREATE: 'CREATE_USERS',
  USERS_UPDATE: 'UPDATE_USERS',
  USERS_DELETE: 'DELETE_USERS',

  NOTIFICATIONS_VIEW: 'VIEW_NOTIFICATIONS',
  NOTIFICATIONS_CREATE: 'CREATE_NOTIFICATIONS',
  NOTIFICATIONS_UPDATE: 'UPDATE_NOTIFICATIONS',
  NOTIFICATIONS_DELETE: 'DELETE_NOTIFICATIONS',

  BOOKABLES_VIEW: 'VIEW_BOOKABLES',
  BOOKABLES_CREATE: 'CREATE_BOOKABLES',
  BOOKABLES_UPDATE: 'UPDATE_BOOKABLES',
  BOOKABLES_DELETE: 'DELETE_BOOKABLES',

  BOOKINGS_VIEW: 'VIEW_BOOKINGS',
  BOOKINGS_CREATE: 'CREATE_BOOKINGS',
  BOOKINGS_UPDATE: 'UPDATE_BOOKINGS',
  BOOKINGS_DELETE: 'DELETE_BOOKINGS',

  MAINTENANCES_VIEW: 'VIEW_MAINTENANCES',
  MAINTENANCES_CREATE: 'CREATE_MAINTENANCES',
  MAINTENANCES_UPDATE: 'UPDATE_MAINTENANCES',
  MAINTENANCES_DELETE: 'DELETE_MAINTENANCES',

  ASSETS_VIEW: 'VIEW_ASSETS',
  ASSETS_CREATE: 'CREATE_ASSETS',
  ASSETS_UPDATE: 'UPDATE_ASSETS',
  ASSETS_DELETE: 'DELETE_ASSETS',

  SUPPLIERS_VIEW: 'VIEW_SUPPLIER',
  SUPPLIERS_CREATE: 'CREATE_SUPPLIER',
  SUPPLIERS_UPDATE: 'UPDATE_SUPPLIER',
  SUPPLIERS_DELETE: 'DELETE_SUPPLIER',

  ENTRY_PERMISSIONS_VIEW: 'VIEW_ENTRY_PERMISSION',
  ENTRY_PERMISSIONS_CREATE: 'CREATE_ENTRY_PERMISSION',
  ENTRY_PERMISSIONS_UPDATE: 'UPDATE_ENTRY_PERMISSION',
  ENTRY_PERMISSIONS_DELETE: 'DELETE_ENTRY_PERMISSION',

  CHART_OF_ACCOUNTS_VIEW: 'VIEW_CHART_OF_ACCOUNT',
  CHART_OF_ACCOUNTS_CREATE: 'CREATE_CHART_OF_ACCOUNT',
  CHART_OF_ACCOUNTS_UPDATE: 'UPDATE_CHART_OF_ACCOUNT',
  CHART_OF_ACCOUNTS_DELETE: 'DELETE_CHART_OF_ACCOUNT',

  INVOICES_VIEW: 'VIEW_INVOICE',
  INVOICES_CREATE: 'CREATE_INVOICE',
  INVOICES_UPDATE: 'UPDATE_INVOICE',
  INVOICES_DELETE: 'DELETE_INVOICE',

  TAGS_VIEW: 'VIEW_TAG',
  TAGS_CREATE: 'CREATE_TAG',
  TAGS_UPDATE: 'UPDATE_TAG',
  TAGS_DELETE: 'DELETE_TAG',

  TEMPLATES_VIEW: 'VIEW_TEMPLATE',
  TEMPLATES_CREATE: 'CREATE_TEMPLATE',
  TEMPLATES_UPDATE: 'UPDATE_TEMPLATE',
  TEMPLATES_DELETE: 'DELETE_TEMPLATE',

  FAQS_VIEW: 'VIEW_FAQS',
  FAQS_CREATE: 'CREATE_FAQS',
  FAQS_UPDATE: 'UPDATE_FAQS',
  FAQS_DELETE: 'DELETE_FAQS',

  CONTACTS_VIEW: 'VIEW_CONTACTS',
  CONTACTS_CREATE: 'CREATE_CONTACTS',
  CONTACTS_UPDATE: 'UPDATE_CONTACTS',
  CONTACTS_DELETE: 'DELETE_CONTACTS',

  GALLERIES_VIEW: 'VIEW_GALLERIES',
  GALLERIES_CREATE: 'CREATE_GALLERIES',
  GALLERIES_UPDATE: 'UPDATE_GALLERIES',
  GALLERIES_DELETE: 'DELETE_GALLERIES',

  PERKS_VIEW: 'VIEW_PERKS',
  PERKS_CREATE: 'CREATE_PERKS',
  PERKS_UPDATE: 'UPDATE_PERKS',
  PERKS_DELETE: 'DELETE_PERKS',

  NEWS_VIEW: 'VIEW_NEWS',
  NEWS_CREATE: 'CREATE_NEWS',
  NEWS_UPDATE: 'UPDATE_NEWS',
  NEWS_DELETE: 'DELETE_NEWS',

  EVENTS_VIEW: 'VIEW_EVENTS',
  EVENTS_CREATE: 'CREATE_EVENTS',
  EVENTS_UPDATE: 'UPDATE_EVENTS',
  EVENTS_DELETE: 'DELETE_EVENTS',

  SETTINGS_VIEW: 'VIEW_SETTINGS',
  SETTINGS_CREATE: 'CREATE_SETTINGS',
  SETTINGS_UPDATE: 'UPDATE_SETTINGS',
  SETTINGS_DELETE: 'DELETE_SETTINGS',

  BUILDINGS_VIEW: 'VIEW_BUILDINGS',
  BUILDINGS_CREATE: 'CREATE_BUILDINGS',
  BUILDINGS_UPDATE: 'UPDATE_BUILDINGS',
  BUILDINGS_DELETE: 'DELETE_BUILDINGS',

  DOCUMENTS_VIEW: 'VIEW_DOCUMENTS',
  DOCUMENTS_CREATE: 'CREATE_DOCUMENTS',
  DOCUMENTS_UPDATE: 'UPDATE_DOCUMENTS',
  DOCUMENTS_DELETE: 'DELETE_DOCUMENTS',

  FEATURE_PROFILE_VIEW: 'VIEW_FEATURE_PROFILE',
  FEATURE_PROFILE_CREATE: 'CREATE_FEATURE_PROFILE',
  FEATURE_PROFILE_UPDATE: 'UPDATE_FEATURE_PROFILE',
  FEATURE_PROFILE_DELETE: 'DELETE_FEATURE_PROFILE',

  PROPERTY_VIEW: 'VIEW_PROPERTY',
  PROPERTY_CREATE: 'CREATE_PROPERTY',
  PROPERTY_UPDATE: 'UPDATE_PROPERTY',
  PROPERTY_DELETE: 'DELETE_PROPERTY',
}
export default PERMISSION
