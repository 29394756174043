import Api from '@/services/api'
import { get } from 'lodash'
import AssetDocument from '@/models/AssetDocument'

export default {
  namespaced: true,
  state: {
    list: [],
    documentDetails: null,
    totalDocuments: 0,
    tempList: [],
    error: null,
  },

  mutations: {
    setDocumentList(state, documents) {
      state.list = documents
    },

    clearDocumentList(state) {
      state.list = []
    },

    setError(state, error) {
      state.error = error
    },

    clearError(state) {
      state.error = null
    },

    setDocumentDetails(state, document) {
      if (document instanceof AssetDocument) {
        state.documentDetails = document
      } else {
        state.documentDetails = new Document(document)
      }
    },

    setTotalDocuments(state, total) {
      state.totalDocuments = total
    },

    setTempDocuments(state, documents) {
      state.tempList.push(documents)
    },

    clearTempDocuments(state) {
      state.tempList = []
    },

    deleteTempDocument(state, id) {
      if (state.tempList.length > 0) {
        state.tempList = state.tempList.filter((item) => item.id !== id)
      }
    },
  },

  actions: {
    async getDocuments({ commit, rootGetters }, params) {
      const query = AssetDocument.page(params.page || 1)
        .limit(params.itemsPerPage || 5)
        .orderBy(params.sort)
      query.params({ paginate: true, asset_id: params.assetId })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }
      if (params.sort) {
        const sort = params.sort.replace('fileName', 'file')
        query.orderBy(sort)
      }

      query.include(['file'])

      const res = await query.get()

      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalDocuments', get(res, 'meta.total', 0))
      }
      commit('setDocumentList', get(res, 'data', []))
    },

    async createDocument({ commit }, { formData, assetId }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          asset_id: assetId,
        },
      }

      const { data } = await Api.post(`asset_documents`, formData, config)
      if (data.error) {
        commit('setError', data.error)
      } else {
        commit('setDocumentDetails', data.data)
        commit('setTempDocuments', data.data)
      }
    },

    async updateDocument({ commit }, { formData, id }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          _method: 'PUT',
        },
      }
      const { data } = await Api.post(`asset_documents/${id}`, formData, config)
      commit('setDocumentDetails', data.data)
    },

    async deleteDocument({ commit }, id) {
      await Api.delete(`asset_documents/${id}`)
    },

    async clearTempDocuments({ commit }, formData) {
      await Api.post(`asset_documents/bulk-delete`)
      commit('clearTempDocuments')
    },

    deleteTempDocument({ commit }, id) {
      commit('deleteTempDocument', id)
    },
  },
}
