import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class WorkOrder extends Model {
  resource() {
    return 'work_orders'
  }

  get issueDate() {
    return dateFormat(this.created_at)
  }

  get dueDate() {
    if (!this.maintenance) return 'NA'
    return dateFormat(this.maintenance.due_date)
  }

  get statusLabel() {
    if (!this.maintenance) return 'NA'
    return this.maintenance.deleted_at ? 'Deleted' : this.maintenance.status
  }

  get supplierName() {
    if (!this.supplier) return 'NA'
    return this.supplier.company_name
  }
}
