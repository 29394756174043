import Building from '@/models/Building'
import Api from '@/services/api'
import bus from '@/store/modules/bus'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    buildingDetails: null,
    totalBuildings: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setBuildingList(state, buildings) {
      state.list = buildings
      state.totalBuildings = 0
    },

    clearBuildingList(state) {
      state.list = []
    },

    setBuildingListMeta(state, meta) {
      state.listMeta = meta
    },

    setBuildingDetails(state, building) {
      if (state.buildingDetails && !building.heading_banner) {
        building.heading_banner = state.buildingDetails.heading_banner
      }

      if (!building.notification_emails) {
        building.notification_emails = []
      }

      if (building instanceof Building) {
        state.buildingDetails = building
      } else {
        state.buildingDetails = new Building(building)
      }
    },

    setBuildingImage(state, payload) {
      if (state.buildingDetails) {
        state.buildingDetails.heading_banner = payload.image
      }
    },

    clearBuildingDetails(state) {
      state.buildingDetails = null
    },

    setTotalBuildings(state, total) {
      state.totalBuildings = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getBuildings({ commit }, params) {
      let res = null

      if (params) {
        const query = Building.page(params.page || 1).limit(
          params.itemsPerPage || 10
        )

        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }
        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        res = await query.get()
        commit('setBuildingList', res.data)
        commit('setBuildingListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setTotalBuildings', get(res, 'meta.total', 0))
        }
      } else {
        res = await Building.params({ paginate: false }).get()
        return res.data
      }
    },

    getBuildingByIndex({ state }, index) {
      return state.list && state.list.length > index ? state.list[index] : null
    },

    async getBuildingDetails({ commit }, id) {
      const { data } = await Api.get(`buildings/${id}?include=heading_banner`)
      commit('setBuildingDetails', data.data)
    },

    async createBuilding({ commit }, formData) {
      const { data } = await Api.post(`buildings`, formData)

      const building = data.data
      bus.$emit('buildingCreated', building)
      commit('filter/addBuilding', building, { root: true })
      commit('setBuildingDetails', data.data)
    },

    async updateBuilding({ commit }, formData) {
      const { data } = await Api.put(`buildings/${formData.id}`, formData)

      const building = data.data
      bus.$emit('buildingUpdated', building)
      commit('filter/updateBuilding', building, { root: true })
      commit('setBuildingDetails', building)
    },

    /**
     * NOTE: We don't have a separate endpoint for updating the image.
     * Use the same `PUT/POST` API we use for updating the details
     */
    async uploadBuildingImage({ commit }, formData) {
      const { data } = await Api.post(
        /**
         * NOTE: As FormData doesn't work w/ `PUT` requests
         * add it in manually
         *
         * https://stackoverflow.com/questions/50691938/patch-and-put-request-does-not-working-with-form-data
         */
        `buildings/${formData.get('id')}?_method=PUT`,
        formData
      )

      commit('setBuildingDetails', data.data)
    },

    async deleteBuildingImage({ commit }, formData) {
      await Api.delete(`media/${formData.id}`, formData)
      commit('setBuildingImage', {
        image: null,
      })
    },
  },
}
