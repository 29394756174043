import Cookies from 'js-cookie'
import Organisation from '@/models/Organisation'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    organisationDetail: null,
  },

  mutations: {
    setOrganisationDetail(state, detail) {
      if (detail instanceof Organisation) {
        state.organisationDetail = detail
      } else {
        state.organisationDetail = new Organisation(detail)
      }
    },
  },

  actions: {
    init({ commit }) {
      const token = Cookies.getJSON('AUTH_TOKEN')
      commit('setToken', token)
    },

    async getOrganisationDetails({ commit }) {
      const { data } = await Api.get('admin/settings/general/organisation')
      commit('setOrganisationDetail', data.data)
    },

    async createOrganisation({ commit }, formData) {
      const { data } = await Api.post('admin/settings/general', formData)
      commit('setOrganisationDetail', data.data)
    },
  },
}
