import Event from '@/models/Event'
import Api from '@/services/api'
import { get, sumBy } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    eventDetails: null,
    totalEvents: 0,
    upcomingEvents: [],
    totalUpcomingEvents: 0,
    eventsGrouped: [],
    queryParams: null,
    scrollPosition: 0,
    timeSlotList: {
      list: [],
      meta: {},
    },
    attendees: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    viewType: 'list',
  },

  mutations: {
    setEventList(state, events) {
      state.list = events
    },

    clearEventList(state) {
      state.list = []
      state.totalEvents = 0
      state.upcomingEvents = []
      state.totalUpcomingEvents = 0
    },

    setEventListMeta(state, meta) {
      state.listMeta = meta
    },

    setEventDetails(state, event) {
      if (state.eventDetails) {
        if (!event.banner) event.banner = state.eventDetails.banner
        if (!event.images) event.images = state.eventDetails.images
      }

      if (event instanceof Event) {
        state.eventDetails = event
      } else {
        state.eventDetails = new Event(event)
      }
    },

    setEventImage(state, payload) {
      if (state.eventDetails) {
        state.eventDetails[payload.image_name] =
          payload.images && payload.image_name === 'banner'
            ? payload.images[0]
            : [...state.eventDetails.images, ...payload.images]
      }
    },

    clearEventDetails(state) {
      state.eventDetails = null
    },

    setEventTotals(state, total) {
      state.totalEvents = total
    },

    setEventsUpcoming(state, events) {
      state.upcomingEvents = events
    },

    setTotalEventsUpcoming(state, total) {
      state.totalUpcomingEvents = total
    },

    setEventsGroupedList(state, eventsGrouped) {
      state.eventsGrouped = eventsGrouped
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },

    setTimeSlotList(state, timeslots) {
      state.timeSlotList.list = timeslots
    },
    setTimeSlotListMeta(state, meta) {
      state.timeSlotList.meta = meta
    },
    setAttendeesList(state, list) {
      state.attendeesList = Array.isArray(list) ? [...list] : []
    },
    addEventTimeSlot(state, eventTimeSlot) {
      state.timeSlotList.list = [...state.timeSlotList.list, eventTimeSlot]
    },
    setAttendeeList(state, attendees) {
      state.attendees.list = attendees
    },
    setAttendeeListMeta(state, meta) {
      state.attendees.listMeta = meta
    },
    clearTimeSlotAttendees(state) {
      state.attendees.list = []
      state.attendees.listMeta = {
        current_page: 0,
        last_page: 1,
      }
    },

    setViewType(state, viewType) {
      state.viewType = viewType
    },
  },

  actions: {
    async getEvents({ commit, rootGetters }, params) {
      const query = Event.page(params.page || 1).limit(
        params.itemsPerPage || 10
      )
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.upcoming) {
        query.where('past_events', 'exclude')
      }

      if (params.past) {
        query.where('past_events', 'only')
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      if (params.past_events) {
        query.where('past_events', params.past_events)
      }

      query.include(['building', 'category', 'createdBy'])

      const res = await query.get()
      commit('setEventList', res.data)
      commit('setEventListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setEventTotals', get(res, 'meta.total', 0))
      }
    },

    async getEventsGroupedCategories({ commit, rootGetters }, params) {
      const query = Event.page(params.page || 1)

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.upcoming) {
        query.where('past_events', 'exclude')
      }

      if (params.past) {
        query.where('past_events', 'only')
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      if (params.past_events) {
        query.where('past_events', params.past_events)
      }

      query.include(['building', 'category', 'createdBy'])

      query.params({ grouped_by: params.grouped_by })

      const res = await query.get()
      commit('setEventsGroupedList', res)
      commit('setEventTotals', sumBy(res, 'count'))
    },

    async getEventsGroupedTitle({ commit, rootGetters }, params) {
      const query = Event.page(params.page || 1).limit(params.itemsPerPage || 5)
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.upcoming) {
        query.where('past_events', 'exclude')
      }

      if (params.past) {
        query.where('past_events', 'only')
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      if (params.past_events) {
        query.where('past_events', params.past_events)
      }

      query.where('title', params.title)

      query.include(['building', 'category', 'createdBy'])

      return query.get()
    },

    getEventByIndex({ state }, index) {
      return state.list && state.list.length > index ? state.list[index] : null
    },

    async getEventDetails({ commit }, id) {
      const { data } = await Api.get(
        `events/${id}/detail?include=banner,images`
      )
      commit('setEventDetails', data.data)
    },

    async createEvent({ commit }, formData) {
      const { data } = await Api.post(`events`, formData)
      commit('setEventDetails', data.data)
      return data
    },

    async updateEvent({ commit }, formData) {
      const { data } = await Api.put(`events/${formData.id}`, formData)
      commit('setEventDetails', data.data)
    },

    async uploadEventImage({ commit }, formData) {
      const { data } = await Api.post(
        `events/${formData.get('id')}/images`,
        formData
      )

      commit('setEventImage', {
        images: data.data,
        image_name: formData.get('image_name'),
      })
    },

    async deleteEventImage({ commit }, formData) {
      await Api.delete(`media/${formData.id}`, formData)

      if (formData.image_name === 'banner') {
        commit('setEventImage', {
          images: [null],
          image_name: formData.image_name,
        })
      }
    },

    async duplicateEvent({ commit }, formData) {
      const { data } = await Api.post(`events/${formData.id}/duplicate`)
      return data.data
    },

    async getUpcomingEvent({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        page: params.page || 1,
        sort: params.sort || '',
        include: ['building', 'category', 'eventConfirmations', 'event.banner'],
        limit: params.itemsPerPage || 5,
      })

      // Add building filter if not nil
      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]=', filteredBuilding)
      }

      const url = `events/upcoming?${queryParams.toString()}`

      const { data } = await Api.get(url)

      const upcomingEvent = data.data.map((booking) => {
        const date = new Date(booking.datetime_start)
        const hours = date.getHours() % 12 || 12 // Convert 0 to 12-hour format
        const period = date.getHours() >= 12 ? 'PM' : 'AM'
        const formattedDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} ${hours}:${('0' + date.getMinutes()).slice(
          -2
        )} ${period}`
        let rsvp = ''
        const eventConfirmations = booking.attendees
        if (booking.event?.rsvp_limit === 0) {
          rsvp = eventConfirmations
        } else {
          rsvp = `${eventConfirmations}/${booking.event.rsvp_limit}`
        }
        return {
          ...booking,
          datetime_start: formattedDate,
          rsvp,
        }
      })

      commit('setEventsUpcoming', upcomingEvent)

      if (get(data, 'meta.total', 0) > 0) {
        commit('setTotalEventsUpcoming', get(data, 'meta.total', 0))
      }
    },

    async getBlockTimeSlotList({ commit }, param) {
      const queryParams = new URLSearchParams({
        page: param.page || 1,
        sort: param.sort || '',
        limit: param.itemsPerPage || 5,
        'filter[past_slots]': param.filterPast ? 'include' : 'exclude',
      })

      const url = `events/${param.id}/time_slots?${queryParams.toString()}`

      const { data } = await Api.get(url)
      commit('setTimeSlotList', data.data)
      commit('setTimeSlotListMeta', data.meta)
    },

    async getLastTimeSlot({ commit }, param) {
      const queryParams = new URLSearchParams({
        page: 1,
        sort: '-start_time',
        limit: 1,
        'filter[past_slots]': 'exclude',
      })

      const url = `events/${param.id}/time_slots?${queryParams.toString()}`

      const { data } = await Api.get(url)

      return data
    },

    async getAttendeesList({ commit }, param) {
      const { data } = await Api.get(
        `/events/${param.event_id}/time_slots/${param.id}/attendees`
      )
      commit('setAttendeesList', data.data)
    },

    async deleteAttendee({ commit }, param) {
      await Api.delete(
        `/events/${param.event_id}/time_slots/${param.time_slot_id}/attendees/${param.id}`
      )
    },

    async createEventTimeSlot({ commit }, param) {
      const { data } = await Api.post(`events/${param.id}/time_slots`, param)
      commit('addEventTimeSlot', data.data)
    },

    async updateEventTimeSlot({ commit }, param) {
      await Api.put(`events/${param.event_id}/time_slots/${param.id}`, param)
    },

    async deleteEventTimeSlot({ commit }, param) {
      await Api.delete(`events/${param.event_id}/time_slots/${param.id}`)
    },

    async createBlockTimeSlot({ commit }, timeSlots) {
      const { data } = await Api.post(`events`, timeSlots)
      commit('addBlockTimeSlot', data.data)
      return data
    },

    async checkExistedTimeSlot({ commit }, timeSlots) {
      const { data } = await Api.post(
        `events/${timeSlots.event_id}/time_slots/check`,
        timeSlots
      )
      return data
    },

    async updateBlockTimeSlotItem({ commit }, formData) {
      const { data } = await Api.put(`events/${formData.id}`, formData)
      commit('setBlockTimeSlotList', data.data)
    },

    async deleteBlockTimeSlotItem({ commit }, id) {
      await Api.delete(`block-time-slots/${id}`)
    },

    async getAttendeesByEventTimeSlot({ commit }, param) {
      let url = `/events/${param.event_id}/time_slots/${param.time_slot_id}/attendees?include=property`

      if (param.search) {
        url += `&filter[search]=${param.search}`
      }

      const { data } = await Api.get(url)
      commit('setAttendeeList', data.data)
      commit('setAttendeeListMeta', data.meta)
    },

    async addAttendeeToTimeSlot({ commit }, params) {
      await Api.post(
        `events/${params.event_id}/time_slots/${params.time_slot_id}/add_attendee`,
        {
          user_id: params.user_id,
          plus: params.plus,
        }
      )
    },

    async exportTimeSlotAttendees({ commit }, params) {
      const file = await Api.get(
        `events/${params.event_id}/time_slots/${params.time_slot_id}/attendees/export`,
        {
          responseType: 'blob',
        }
      )
      return file
    },

    async exportEvents({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        sort: params.sort || '',
      })

      if (params?.past_events) {
        queryParams.append('filter[past_events]=', params.past_events)
      }

      // Add building filter if not nil
      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]=', filteredBuilding)
      }

      if (params?.search) {
        queryParams.append('filter[search]=', params.search)
      }

      const url = `events/export?${queryParams.toString()}`

      const file = await Api.get(url, {
        responseType: 'blob',
      })
      return file
    },
  },
}
