import Model from './Model'

export default class AssetDocument extends Model {
  resource() {
    return 'asset_documents'
  }

  get fileName() {
    if (!this.file) return 'NA'
    return this.file.name
  }
}
