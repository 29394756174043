export default [
  {
    path: '/maintenance',
    name: 'maintenance',
    redirect: { name: 'maintenance.dashboard' },
    component: () =>
      import(/* webpackChunkName: "maintenance" */ '@/views/Home/Maintenance'),
    children: [
      {
        path: 'dashboard',
        name: 'maintenance.request',
        meta: {
          requiresPermissions: ['VIEW_MAINTENANCES'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.dashboard" */ '@/views/Home/Maintenance/Requests'
          ),
      },
      {
        path: 'requests/:id',
        name: 'maintenance.request.details',
        meta: {
          requiresPermissions: ['VIEW_MAINTENANCES'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.request.details" */ '@/views/Home/Maintenance/Requests/RequestDetails'
          ),
      },
      {
        path: 'categories',
        name: 'maintenance.categories',
        meta: {
          requiresPermissions: ['VIEW_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.categories" */ '@/views/Home/Maintenance/Categories'
          ),
      },
      {
        path: 'categories/create',
        name: 'maintenance.category.create',
        meta: {
          requiresPermissions: ['CREATE_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.category.create" */ '@/views/Home/Maintenance/Categories/CreateCategory'
          ),
      },
      {
        path: 'categories/:id',
        name: 'maintenance.category.details',
        meta: {
          requiresPermissions: ['VIEW_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.category.details" */ '@/views/Home/Maintenance/Categories/CategoryDetails'
          ),
      },
      {
        path: 'urgencies',
        name: 'maintenance.urgencies',
        meta: {
          requiresPermissions: ['VIEW_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.urgencies" */ '@/views/Home/Maintenance/Urgencies'
          ),
      },
      {
        path: 'urgencies/create',
        name: 'maintenance.urgency.create',
        meta: {
          requiresPermissions: ['CREATE_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.urgency.create" */ '@/views/Home/Maintenance/Urgencies/CreateUrgency'
          ),
      },
      {
        path: 'urgencies/:id',
        name: 'maintenance.urgency.details',
        meta: {
          requiresPermissions: ['VIEW_SETTINGS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.urgency.details" */ '@/views/Home/Maintenance/Urgencies/UrgencyDetails'
          ),
      },
      {
        path: 'assets',
        name: 'maintenance.assets',
        meta: {
          requiresPermissions: ['VIEW_ASSETS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.assets" */ '@/views/Home/Maintenance/Assets'
          ),
      },
      {
        path: 'assets/create',
        name: 'maintenance.asset.create',
        meta: {
          requiresPermissions: ['CREATE_ASSETS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.asset.create" */ '@/views/Home/Maintenance/Assets/CreateAsset.vue'
          ),
      },
      {
        path: 'assets/:id',
        name: 'maintenance.asset.details',
        meta: {
          requiresPermissions: ['UPDATE_ASSETS'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.asset.details" */ '@/views/Home/Maintenance/Assets/AssetDetails.vue'
          ),
      },
      {
        path: 'suppliers',
        name: 'maintenance.suppliers',
        meta: {
          requiresPermissions: ['VIEW_SUPPLIER'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.suppliers" */ '@/views/Home/Maintenance/Suppliers'
          ),
      },
      {
        path: 'suppliers/create',
        name: 'maintenance.supplier.create',
        meta: {
          requiresPermissions: ['CREATE_SUPPLIER'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.supplier.create" */ '@/views/Home/Maintenance/Suppliers/CreateSupplier.vue'
          ),
      },
      {
        path: 'suppliers/:id',
        name: 'maintenance.supplier.details',
        meta: {
          requiresPermissions: ['UPDATE_SUPPLIER'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.supplier.create" */ '@/views/Home/Maintenance/Suppliers/SupplierDetails.vue'
          ),
      },
      {
        path: 'tags',
        name: 'maintenance.tags',
        meta: {
          requiresPermissions: ['VIEW_TAG'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.tags" */ '@/views/Home/Maintenance/Tags'
          ),
      },
      {
        path: 'tags/create',
        name: 'maintenance.tag.create',
        meta: {
          requiresPermissions: ['CREATE_TAG'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.tag.create" */ '@/views/Home/Maintenance/Tags/CreateTag.vue'
          ),
      },
      {
        path: 'tags/:id',
        name: 'maintenance.tag.details',
        meta: {
          requiresPermissions: ['UPDATE_TAG'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.tag.create" */ '@/views/Home/Maintenance/Tags/TagDetails.vue'
          ),
      },
      {
        path: 'entry-permissions',
        name: 'maintenance.entry_permissions',
        meta: {
          requiresPermissions: ['VIEW_ENTRY_PERMISSION'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.entry_permissions" */ '@/views/Home/Maintenance/EntryPermission'
          ),
      },
      {
        path: 'entry-permissions/create',
        name: 'maintenance.entry_permission.create',
        meta: {
          requiresPermissions: ['CREATE_ENTRY_PERMISSION'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.entry_permission.create" */ '@/views/Home/Maintenance/EntryPermission/CreateEntryPermission.vue'
          ),
      },
      {
        path: 'entry-permissions/:id',
        name: 'maintenance.entry_permission.details',
        meta: {
          requiresPermissions: ['UPDATE_ENTRY_PERMISSION'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.entry_permission.create" */ '@/views/Home/Maintenance/EntryPermission/EntryPermissionDetails.vue'
          ),
      },
      {
        path: 'chart-of-accounts',
        name: 'maintenance.chart_of_accounts',
        meta: {
          requiresPermissions: ['VIEW_CHART_OF_ACCOUNT'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.chart_of_accounts" */ '@/views/Home/Maintenance/ChartOfAccount'
          ),
      },
      {
        path: 'invoices',
        name: 'maintenance.invoices',
        meta: {
          requiresPermissions: ['VIEW_INVOICE'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.invoices" */ '@/views/Home/Maintenance/Invoices'
          ),
      },
      {
        path: 'invoices/:id',
        name: 'maintenance.invoice.details',
        meta: {
          requiresPermissions: ['UPDATE_INVOICE'],
        },
        component: () =>
          import(
            /* webpackChunkName: "maintenance.invoices.create" */ '@/views/Home/Maintenance/Invoices/InvoiceDetails.vue'
          ),
      },
    ],
  },
]
