import Api from '@/services/api'
import { get } from 'lodash'
import Insurance from '@/models/Insurance'

export default {
  namespaced: true,
  state: {
    list: [],
    insuranceDetails: null,
    totalInsurances: 0,
    tempList: [],
    error: null,
  },

  mutations: {
    setInsuranceList(state, insurances) {
      state.list = insurances
    },

    clearInsuranceList(state) {
      state.list = []
    },

    setError(state, error) {
      state.error = error
    },

    clearError(state) {
      state.error = null
    },

    setInsuranceDetails(state, insurance) {
      if (insurance instanceof Insurance) {
        state.insuranceDetails = insurance
      } else {
        state.insuranceDetails = new Insurance(insurance)
      }
    },

    setTotalInsurances(state, total) {
      state.totalInsurances = total
    },

    setTempInsurances(state, insurances) {
      if (Array.isArray(insurances)) {
        const insuranceInstances = insurances.map((item) =>
          item instanceof Insurance ? item : new Insurance(item)
        )
        state.tempList.push(...insuranceInstances)
      } else {
        state.tempList.push(
          insurances instanceof Insurance
            ? insurances
            : new Insurance(insurances)
        )
      }
    },

    clearTempInsurances(state) {
      state.tempList = []
    },

    deleteTempInsurance(state, id) {
      if (state.tempList.length > 0) {
        state.tempList = state.tempList.filter((item) => item.id !== id)
      }
    },
  },

  actions: {
    async getInsurances({ commit, rootGetters }, params) {
      const query = Insurance.page(params.page || 1)
        .limit(params.itemsPerPage || 5)
        .orderBy(params.sort)
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }
      if (params.sort) {
        const sort = params.sort.replace('fileName', 'file')
        query.orderBy(sort)
      }

      query.where('supplier_id', params.supplierId)

      const res = await query.get()

      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalInsurances', get(res, 'meta.total', 0))
      }
      commit('setInsuranceList', get(res, 'data', []))
    },

    async createInsurance({ commit }, { formData, supplierId }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          supplier_id: supplierId,
        },
      }

      const { data } = await Api.post(`insurances`, formData, config)
      if (data.error) {
        commit('setError', data.error)
      } else {
        commit('setInsuranceDetails', data.data)
        commit('setTempInsurances', data.data)
      }
    },

    async updateInsurance({ commit }, { formData, id }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          _method: 'PUT',
        },
      }
      const { data } = await Api.post(`insurances/${id}`, formData, config)
      commit('setInsuranceDetails', data.data)
    },

    async deleteInsurance({ commit }, id) {
      await Api.delete(`insurances/${id}`)
    },

    async clearTempInsurances({ commit }, formData) {
      await Api.post(`insurances/bulk-delete`)
      commit('clearTempInsurances')
    },

    deleteTempInsurance({ commit }, id) {
      commit('deleteTempInsurance', id)
    },
  },
}
