import Urgency from '@/models/Urgency'
import Api from '@/services/api'
import { each, find } from 'lodash'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    urgencyDetails: null,
    queryParams: null,
    scrollPosition: 0,
    initList: null,
  },

  mutations: {
    setUrgencyList(state, urgencies) {
      each(urgencies, (urgency) => {
        const exist = find(state.list, { id: urgency.id })
        if (!exist) {
          state.list.push(new Urgency(urgency))
        }
      })
    },

    setInitUrgencies(state, urgencies) {
      each(urgencies, (urgency) => {
        const exist = find(state.initList, { id: urgency.id })
        if (!exist) {
          state.initList.push(new Urgency(urgency))
        }
      })
    },

    clearUrgencyList(state) {
      state.list = []
      state.initList = []
    },

    setUrgencyListMeta(state, meta) {
      state.listMeta = meta
    },

    setUrgencyDetails(state, urgency) {
      if (urgency instanceof Urgency) {
        state.urgencyDetails = urgency
      } else {
        state.urgencyDetails = new Urgency(urgency)
      }
    },

    clearUrgencyDetails(state) {
      state.urgencyDetails = null
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getUrgencies({ commit, rootGetters }, params) {
      let res = null

      if (params) {
        const query = Urgency.page(params.page || 1).limit(params.limit || 30)
        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }

        if (params.filter) {
          query.where('is_active', params.filter)
        } else {
          query.where('is_active', false)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        // Add building filter if not nil
        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.include('buildings')

        query.where('type', CATEGORY_TYPE.urgency)
        res = await query.get()

        commit('setUrgencyList', res.data)
        commit('setInitUrgencies', res.data)

        commit('setUrgencyListMeta', res.meta)
      } else {
        res = await Urgency.where('type', CATEGORY_TYPE.urgency)
          .params({ paginate: false })
          .get()

        return res.data
      }
    },

    async getForSelects({ commit, rootGetters }, params) {
      const query = Urgency.where('type', CATEGORY_TYPE.urgency).params({
        paginate: false,
      })

      if (params.filter.buildingId) {
        query.where('building_id', params.filter.buildingId)
      }

      const res = await query.get()

      return res.data
    },

    async getUrgencyDetails({ commit }, id) {
      const { data } = await Api.get(`categories/${id}/?include=buildings`)
      commit('setUrgencyDetails', data.data)
    },

    async createUrgency({ commit }, formData) {
      formData.type = CATEGORY_TYPE.urgency
      const { data } = await Api.post(`categories`, formData)
      commit('setUrgencyDetails', data.data)
    },

    async updateUrgency({ commit }, formData) {
      formData.type = CATEGORY_TYPE.urgency
      const { data } = await Api.put(
        `categories/${formData.id}/?include=buildings`,
        formData
      )
      commit('setUrgencyDetails', data.data)
    },
  },
}
