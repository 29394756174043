import Model from './Model'

export default class Quote extends Model {
  resource() {
    return 'maintenance_quotes'
  }

  get fileName() {
    if (!this.file) return 'NA'
    return this.file.name
  }

  get supplierName() {
    if (!this.supplier) return 'NA'
    return this.supplier.company_name
  }
}
