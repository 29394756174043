import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import building from './modules/building'
import bookable from './modules/bookable'
import booking from './modules/booking'
import maintenance from './modules/maintenance'
import category from './modules/category'
import urgency from './modules/urgency'
import faq from './modules/faq'
import contact from './modules/contact'
import notification from './modules/notification'
import perk from './modules/perk'
import event from './modules/event'
import attendee from './modules/attendee'
import filter from './modules/filter'
import settings from './modules/settings'
import news from './modules/news'
import gallery from './modules/gallery'
import versionControl from './modules/version-control'
import snackbar from './modules/snackbar'
import property from './modules/property'
import comment from './modules/comment'
import document from './modules/document'
import folder from './modules/folder'
import team from './modules/team'
import profile from './modules/profile'
import ssoProvider from './modules/sso-provider'
import lobbyTv from './modules/lobby-tv'
import timeslot from './modules/time-slot'
import env from '../services/env'
import './modules/bus'
import asset from '@/store/modules/asset'
import assetDocument from '@/store/modules/asset-document'
import supplier from '@/store/modules/supplier'
import insurance from '@/store/modules/insurance'
import workOrder from '@/store/modules/work-order'
import quote from '@/store/modules/quote'
import tag from '@/store/modules/tag'
import entryPermission from '@/store/modules/entry-permission'
import chartOfAccount from '@/store/modules/chart-of-account'
import invoice from '@/store/modules/invoice'
import template from '@/store/modules/template'
import organisation from '@/store/modules/organisation'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    building,
    bookable,
    booking,
    maintenance,
    asset,
    assetDocument,
    supplier,
    tag,
    invoice,
    entryPermission,
    chartOfAccount,
    insurance,
    workOrder,
    quote,
    category,
    urgency,
    faq,
    contact,
    notification,
    perk,
    event,
    attendee,
    filter,
    settings,
    versionControl,
    snackbar,
    news,
    gallery,
    property,
    comment,
    document,
    folder,
    team,
    profile,
    lobbyTv,
    ssoProvider,
    template,
    organisation,
    timeslot,
  },
  state: {
    controls: {
      showDrawer: true,
    },
  },
  getters: {
    appName() {
      return env.appName
    },
    domainName() {
      return env.domain
    },
    logo() {
      return require('@/assets/images/' +
        (env.domain.length === 0 ? '' : env.domain + '/') +
        'logo.png')
    },
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    },
  },
})
