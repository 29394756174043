import Document from '@/models/Document'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    documentDetails: null,
    totalDocuments: 0,
    tempList: [],
    error: null,
  },

  mutations: {
    setDocumentList(state, documents) {
      state.list = documents
    },

    clearDocumentList(state) {
      state.list = []
    },

    setError(state, error) {
      state.error = error
    },

    clearError(state) {
      state.error = null
    },

    setDocumentDetails(state, document) {
      if (document instanceof Document) {
        state.documentDetails = document
      } else {
        state.documentDetails = new Document(document)
      }
    },

    setTotalDocuments(state, total) {
      state.totalDocuments = total
    },

    setTempDocuments(state, documents) {
      state.tempList.push(documents)
    },

    clearTempDocuments(state) {
      state.tempList = []
    },

    deleteTempDocument(state, id) {
      if (state.tempList.length > 0) {
        state.tempList = state.tempList.filter((item) => item.id !== id)
      }
    },
  },

  actions: {
    async getDocuments({ commit, rootGetters }, params) {
      let res = null

      if (params) {
        const query = Document.page(params.page || 1).limit(
          params.itemsPerPage || 5
        )
        query.params({ paginate: true, folderId: params.folderId })

        if (params.sort) {
          query.orderBy(params.sort)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }
        query.include(['file'])
        res = await query.get()
      } else {
        res = await Document.params({ paginate: false }).get()
      }
      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalDocuments', get(res, 'meta.total', 0))
      }
      commit('setDocumentList', get(res, 'data', []))
    },

    async createDocument({ commit }, formData) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await Api.post(`documents`, formData, config)
      if (data.error) {
        commit('setError', data.error)
      } else {
        commit('setDocumentDetails', data.data)
        commit('setTempDocuments', data.data)
      }
    },

    async updateDocument({ commit }, formData) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await Api.post(
        `updateDocuments/${formData.get('id')}`,
        formData,
        config
      )
      commit('setDocumentDetails', data.data)
    },

    async deleteDocument({ commit }, id) {
      await Api.delete(`documents/${id}`)
    },

    async clearTempDocuments({ commit }, formData) {
      await Api.post(`documents/bulk-delete`)
      commit('clearTempDocuments')
    },

    deleteTempDocument({ commit }, id) {
      commit('deleteTempDocument', id)
    },
  },
}
