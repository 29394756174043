import ChartOfAccount from '@/models/ChartOfAccount'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    chartOfAccountDetails: null,
    totalChartOfAccounts: 0,
    params: {
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        search: '',
      },
      filter: {
        search: '',
      },
      status: 0,
    },
  },

  mutations: {
    setChartOfAccountList(state, chartOfAccounts) {
      state.list = chartOfAccounts
    },

    clearChartOfAccountList(state) {
      state.list = []
      state.totalChartOfAccounts = 0
    },

    setChartOfAccountListMeta(state, meta) {
      state.listMeta = meta
    },

    setChartOfAccountDetails(state, chartOfAccount) {
      if (state.chartOfAccountDetails) {
        if (!chartOfAccount.thumbnail_banner)
          chartOfAccount.thumbnail_banner =
            state.chartOfAccountDetails.thumbnail_banner
      }

      if (chartOfAccount instanceof ChartOfAccount) {
        state.chartOfAccountDetails = chartOfAccount
      } else {
        state.chartOfAccountDetails = new ChartOfAccount(chartOfAccount)
      }
    },

    clearChartOfAccountDetails(state) {
      state.chartOfAccountDetails = null
    },

    setChartOfAccountTotals(state, total) {
      state.totalChartOfAccounts = total
    },

    setParams(state, params) {
      state.params = params
    },
  },

  actions: {
    async getChartOfAccounts({ commit, rootGetters }, params) {
      if (params) {
        const query = ChartOfAccount.page(params.options.page || 1)
          .limit(params.options.itemsPerPage || 10)
          .orderBy(params.options.sort)
          .params({ paginate: true })
          .where('is_active', params.status === 0)

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        const res = await query.get()
        commit('setChartOfAccountList', res.data)
        commit('setChartOfAccountListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setChartOfAccountTotals', get(res, 'meta.total', 0))
        }
      } else {
        const res = await ChartOfAccount.params({ paginate: false }).get()
        return res.data
      }
    },

    async getForSelects({ commit, rootGetters }, params) {
      const query = ChartOfAccount.params({ paginate: false })

      const res = await query.get()

      return res.data
    },

    async getChartOfAccountDetails({ commit }, id) {
      const { data } = await Api.get(`chart_of_accounts/${id}/`)
      commit('setChartOfAccountDetails', data.data)
    },

    async createChartOfAccount({ commit }, formData) {
      const { data } = await Api.post(`chart_of_accounts`, formData)
      commit('setChartOfAccountDetails', data.data)
    },

    async updateChartOfAccount({ commit }, formData) {
      const { data } = await Api.post(
        `chart_of_accounts/${formData.id}/?_method=PUT`,
        formData
      )
      commit('setChartOfAccountDetails', data.data)
    },

    async archiveChartOfAccount({ commit }, { id, formData }) {
      const { data } = await Api.post(
        `chart_of_accounts/${id}/archive/?_method=PUT`,
        formData
      )
      return data.data
    },
  },
}
