import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Template extends Model {
  resource() {
    return 'templates'
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get updatedDate() {
    return dateFormat(this.updated_at)
  }
}
