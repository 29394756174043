import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Insurance extends Model {
  resource() {
    return 'insurances'
  }

  get addedDate() {
    return dateFormat(this.created_at, 'DD/MM/YYYY')
  }

  get expiryDate() {
    return dateFormat(this.expiry_date, 'DD/MM/YYYY')
  }
}
