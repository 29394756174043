import Faq from '@/models/Faq'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    faqDetails: null,
    totalFaqs: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setFaqList(state, faqs) {
      state.list = faqs
    },

    clearFaqList(state) {
      state.list = []
      state.totalFaqs = 0
    },

    setFaqListMeta(state, meta) {
      state.listMeta = meta
    },

    setFaqDetails(state, faq) {
      if (faq instanceof Faq) {
        state.faqDetails = faq
      } else {
        state.faqDetails = new Faq(faq)
      }
    },

    clearFaqDetails(state) {
      state.faqDetails = null
    },

    setTotalFaqs(state, total) {
      state.totalFaqs = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getFaqs({ commit, rootGetters }, params) {
      let res = null

      if (params) {
        const query = Faq.page(params.page || 1).limit(
          params.itemsPerPage || 30
        )
        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.include(['buildings'])

        res = await query.get()
      } else {
        res = await Faq.params({ paginate: false }).get()
      }

      commit('setFaqList', res.data)
      commit('setFaqListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalFaqs', get(res, 'meta.total', 0))
      }
    },

    async getFaqDetails({ commit }, id) {
      const { data } = await Api.get(`faqs/${id}?include=buildings`)
      commit('setFaqDetails', data.data)
    },

    async createFaq({ commit }, formData) {
      const { data } = await Api.post(`faqs`, formData)
      commit('setFaqDetails', data.data)
    },

    async updateFaq({ commit }, formData) {
      const { data } = await Api.put(`faqs/${formData.id}`, formData)
      commit('setFaqDetails', data.data)
    },
  },
}
