import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Supplier extends Model {
  resource() {
    return 'suppliers'
  }

  get buildingName() {
    if (!this.buildings || this.buildings.length === 0) return 'NA'
    return this.buildings.map((building) => building.name).join(', ')
  }

  get categoryName() {
    if (!this.category) return 'NA'
    return this.category.label
  }

  get insuranceExpiry() {
    let color = 'black'
    if (!this.insurances || this.insurances.length === 0)
      return { formattedDate: 'NA', color }

    const latestInsurance = this.insurances[this.insurances.length - 1]
    const expiryDate = new Date(latestInsurance.expiry_date)
    const today = new Date()

    const timeDiff = expiryDate - today
    const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))

    if (daysRemaining < 0) {
      color = 'red'
    } else if (daysRemaining <= 30) {
      color = 'orange'
    }

    const formattedExpiryDate = dateFormat(expiryDate, 'DD/MM/YYYY')

    return { formattedDate: formattedExpiryDate, color }
  }
}
