import Api from '@/services/api'
import { get } from 'lodash'
import Quote from '@/models/Quote'

export default {
  namespaced: true,
  state: {
    list: [],
    quoteDetails: null,
    totalQuotes: 0,
    tempList: [],
    error: null,
  },

  mutations: {
    setQuoteList(state, quotes) {
      state.list = quotes
    },

    clearQuoteList(state) {
      state.list = []
    },

    setError(state, error) {
      state.error = error
    },

    clearError(state) {
      state.error = null
    },

    setQuoteDetails(state, quote) {
      if (quote instanceof Quote) {
        state.quoteDetails = quote
      } else {
        state.quoteDetails = new Quote(quote)
      }
    },

    setTotalQuotes(state, total) {
      state.totalQuotes = total
    },

    setTempQuotes(state, quotes) {
      state.tempList.push(quotes)
    },

    clearTempQuotes(state) {
      state.tempList = []
    },

    deleteTempQuote(state, id) {
      if (state.tempList.length > 0) {
        state.tempList = state.tempList.filter((item) => item.id !== id)
      }
    },
  },

  actions: {
    async getQuotes({ commit, rootGetters }, params) {
      const query = Quote.page(params.page || 1)
        .limit(params.itemsPerPage || 5)
        .orderBy(params.sort)
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.supplierId) {
        query.where('supplier_id', params.supplierId)
      }

      if (params.maintenanceId) {
        query.where('maintenance_id', params.maintenanceId)
      }

      query.include(['supplier,file'])

      const res = await query.get()

      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalQuotes', get(res, 'meta.total', 0))
      }
      commit('setQuoteList', get(res, 'data', []))
    },

    async createQuote({ commit }, { formData }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await Api.post(`maintenance_quotes`, formData, config)
      if (data.error) {
        commit('setError', data.error)
      } else {
        commit('setQuoteDetails', data.data)
        commit('setTempQuotes', data.data)
      }
    },

    async updateQuote({ commit }, { formData, id }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          _method: 'PUT',
        },
      }

      const { data } = await Api.post(
        `maintenance_quotes/${id}`,
        formData,
        config
      )
      commit('setQuoteDetails', data.data)
    },

    async deleteQuote({ commit }, id) {
      await Api.delete(`maintenance_quotes/${id}`)
    },

    async clearTempQuotes({ commit }, formData) {
      await Api.post(`maintenance_quotes/bulk-delete`)
      commit('clearTempQuotes')
    },

    deleteTempQuote({ commit }, id) {
      commit('deleteTempQuote', id)
    },
  },
}
