import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Asset extends Model {
  resource() {
    return 'assets'
  }
  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }
  get addedDate() {
    return dateFormat(this.created_at, 'DD/MM/YYYY')
  }

  get updatedDate() {
    return dateFormat(this.updated_at, 'DD/MM/YYYY')
  }
}
