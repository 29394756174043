import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Maintenance extends Model {
  resource() {
    return 'maintenances'
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get assignedName() {
    if (!this.user) return 'NA'
    return this.user.full_name
  }

  get categoryLabel() {
    if (!this.category) return 'NA'
    return this.category.label
  }

  get urgencyLabel() {
    if (!this.urgency) return 'NA'
    return this.urgency.label
  }

  get statusLabel() {
    return this.deleted_at ? 'Deleted' : this.status
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get updatedDate() {
    return dateFormat(this.updated_at)
  }

  get deletedDate() {
    return dateFormat(this.deleted_at)
  }
}
