import Tag from '@/models/Tag'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    tagDetails: null,
    totalTags: 0,
    params: {
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        search: '',
      },
      filter: {
        search: '',
      },
      status: 0,
    },
    scrollPosition: 0,
  },

  mutations: {
    setTagList(state, tags) {
      state.list = tags
    },

    clearTagList(state) {
      state.list = []
      state.totalTags = 0
    },

    setTagListMeta(state, meta) {
      state.listMeta = meta
    },

    setTagDetails(state, tag) {
      if (state.tagDetails) {
        if (!tag.thumbnail_banner)
          tag.thumbnail_banner = state.tagDetails.thumbnail_banner
      }

      if (tag instanceof Tag) {
        state.tagDetails = tag
      } else {
        state.tagDetails = new Tag(tag)
      }
    },

    clearTagDetails(state) {
      state.tagDetails = null
    },

    setTagTotals(state, total) {
      state.totalTags = total
    },

    setParams(state, params) {
      state.params = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getTags({ commit, rootGetters }, params) {
      if (params) {
        const query = Tag.page(params.options.page || 1)
          .limit(params.options.itemsPerPage || 10)
          .orderBy(params.options.sort)
          .params({ paginate: true })
          .where('is_active', params.status === 0)

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        // Add building filter if not nil
        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.include(['buildings'])

        const res = await query.get()
        commit('setTagList', res.data)
        commit('setTagListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setTagTotals', get(res, 'meta.total', 0))
        }
      } else {
        const res = await Tag.params({ paginate: false }).get()
        return res.data
      }
    },

    async getForSelects({ commit, rootGetters }, params) {
      const query = Tag.params({ paginate: false })

      if (params.filter.buildingId) {
        query.where('building_id', params.filter.buildingId)
      }

      const res = await query.get()

      return res.data
    },

    async getTagDetails({ commit }, id) {
      const { data } = await Api.get(`tags/${id}/?include=buildings`)
      commit('setTagDetails', data.data)
    },

    async createTag({ commit }, formData) {
      const { data } = await Api.post(`tags`, formData)
      commit('setTagDetails', data.data)
    },

    async updateTag({ commit }, formData) {
      const { data } = await Api.put(
        `tags/${formData.id}/?include=buildings`,
        formData
      )
      commit('setTagDetails', data.data)
    },
  },
}
